import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from 'constants/api.constant'
import { initialState, setUser } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'

import BaseService from 'services/BaseService'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { apiSignIn } from 'services/AuthService'
import { apiSignOut } from 'services/AuthService'
import appConfig from 'configs/app.config'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const query = useQuery()

  const { token, signedIn, refreshToken } = useSelector((state) => state.auth.session)

  // eslint-disable-next-line no-unused-vars
  const _data = useSelector((state) => {
    // console.log({ state })
    return state
  })

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values)

      if (resp.data) {
        const { token } = resp.data.data.tokens.access
        const { user } = resp.data.data
        // const token = resp,data.data.token
        dispatch(onSignInSuccess(resp.data.data.tokens))

        // TODO: make sure it only talk with peristence data, however due the request needs to wait until it stored
        // into storage, sometimes, it never sends the token
        // Hack
        BaseService.interceptors.request.use(
          (config) => {
            if (token) {
              config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${token}`
            }

            return config
          },
          (error) => {
            return Promise.reject(error)
          },
        )
        dispatch(setUser(user))
        const redirectUrl = query.get(REDIRECT_URL_KEY)

        if (user.statusProfile || user.Role.name === 'SuperAdmin') {
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        } else {
          navigate('/welcome')
        }
        return {
          status: 'success',
          message: '',
        }
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      }
    }
  }

  const signOut = async () => {
    try {
      await apiSignOut({ refreshToken: refreshToken })
      dispatch(onSignOutSuccess())
      dispatch(setUser(initialState))
      navigate(appConfig.unAuthenticatedEntryPath)
    } catch (error) {
      console.log(error)
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  }
}

export default useAuth
